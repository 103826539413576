<template>
  <!-- 额度管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="addRow">
          申请额度
        </el-button>
      </div>
      <!-- 表格  分页 -->
      <Table :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <pdf-contract v-if="agreementConfig.visible" :visible.sync="agreementConfig.visible" :url="agreementConfig.data.proxyContractUrl" @confirmSign="confirmSign" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import PdfContract from '@/components/PdfContract'
import { roundUpToInteger } from '@/unit/index'
import { agentCreditInfoRefusedSign, agentFinancialInfoList, agentCreditInfoCustomersSign } from '@/http/custApi/quotaManage'
export default {
  components: {
    Table,
    FormSearch,
    Pagination,
    PdfContract
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        cmpCode: this.$getUserInfoParameter('cmpId')
      },
      itemData: [
        { label: '额度编号', prop: 'creditNo', width: 140 },
        { label: '代采商', prop: 'fundsCmpName', width: 160 },
        { label: '授信额度(万元)', prop: 'creditTotalAmt', width: 120, type: 'money' },
        { label: '已用额度(元)', prop: 'creditUsedAmt', width: 120, type: 'money' },
        { label: '冻结额度(元)', prop: 'creditFrzAmt', width: 120, type: 'money' },
        { label: '可用额度(元)', prop: 'creditAvlbAmt', width: 120, type: 'money' },
        { label: '额度到期日', prop: 'validDate', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'creditStatus', width: 120, child: this.$store.getters.getDictionaryItem('CUST_CREDIT_STATUS') }
        // { label: '驳回原因', prop: 'rejectionReasons', width: 80 }
      ],
      formItemArr: [
        { type: 'input', label: '额度编号', value: 'creditNo' },
        { type: 'input', label: '代采商', value: 'fundsCmpName' },
        { type: 'select', label: '状态', value: 'creditStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('CUST_CREDIT_STATUS') }
      ],
      listData: [],
      operationButton: [
        { val: 'creditStatus', num: 4, bType: 'primary', label: '签署协议', handleEvent: this.agreementSigning },
        { val: 'creditStatus', num: 4, bType: 'primary', label: '拒绝签署', handleEvent: this.refusedSign },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0,
      agreementConfig: {
        visible: false,
        data: {}
      }
    }
  },
  created() {
    if (this.$route.query.creditStatus) this.formInline.creditStatus = this.$route.query.creditStatus
  },
  methods: {
    // 拒绝签署
    async refusedSign(row) {
      const confirm = await this.$confirm(`您的操作将拒绝此条授信签署，是否继续？`, '提示', { type: 'error' }).catch(err => err)
      if (confirm !== 'confirm') return
      agentCreditInfoRefusedSign({ id: row.id, creditStatus: row.creditStatus }, () => {
        this.$message.success('成功！')
        this.getdata(true)
      })
    },
    // 获取列表
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentFinancialInfoList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.creditTotalAmt = roundUpToInteger((Number(item.creditTotalAmt) || 0) / 10000)
        })
        this.total = res.data.total
      })
    },
    // 添加
    addRow() {
      // const index = this.listData.findIndex(item => [0, 1, 2, 3, 4, 5, 6].includes(item.creditStatus))
      // if (index !== -1) return this.$message.warning('额度已存在，不能再次发起额度申请')
      this.$router.push({
        path: '/quotaManage/creditApplication'
      })
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({
        path: '/quotaManage/quotaInfo',
        query: { id: row.id }
      })
    },
    // 协议签署
    agreementSigning(row) {
      this.agreementConfig.visible = true
      this.agreementConfig.data = row
    },
    // 确定签署
    confirmSign() {
      agentCreditInfoCustomersSign(this.agreementConfig.data.id
        , res => {
          this.$message.success('成功！')
          this.agreementConfig.visible = false
          this.agreementConfig.data = {}
          window.open(res.data)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-operation {
  margin: 20px 0;
  text-align: right;
}
</style>
