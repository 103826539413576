import { postRequest, getRequest } from './index'

/* 企业财务信息管理 */
// 新增
export const agentFinancialInfoAdd = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/apply', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除
export const agentFinancialInfoDelete = (id, successCallback, failureCallback) => {
  getRequest('/agentFinancialInfo/delete/?id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取详情
export const agentFinancialInfoDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/details?operFlat=1&id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 列表分页 1-经销商端，2-垫资端，3-运营端
export const agentFinancialInfoList = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/listByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 更新
export const agentFinancialInfoUpdate = (data, successCallback, failureCallback) => {
  postRequest('/agentFinancialInfo/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
/* 授权额度信息管理 */
// 列表分页
export const agentCreditInfoList = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增
export const agentCreditInfoAdd = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取详情
export const agentCreditInfoDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/details', id, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信资方名称下拉列表
export const agentCreditInfoGetCmpNameList = (cmpName, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/getCmpNameList', cmpName, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信下游客户签署（只传授信额度对象id
export const agentCreditInfoCustomersSign = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/getEsignUrl?creditId=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信签署 拒绝签署
export const agentCreditInfoRefusedSign = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/refusedSign', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
